import { OptionProps, usePaginationElements } from '@tymbe/components/table';
import { useFieldApi } from 'informed';
import { defaultPageSizeOptions } from '@tymbe/components/table/table.utils';
import { ArrowLeftIcon, ArrowRightIcon } from '../icons';

import { TySelect } from '../inputs/Select';
import {
  PaginationButton,
  PaginationRadio,
  PaginationRadioGroup,
} from './TablePaginationElements';
import classNames from 'classnames';

export type TablePaginationProps = {
  rowsCount?: number;
  onPageChange?: (page: number) => void;
  pageSizeOptions?: OptionProps[];
  className?: string;
};

export const TyTablePagination = ({
  rowsCount = 0,
  onPageChange,
  pageSizeOptions = defaultPageSizeOptions,
  className,
}: TablePaginationProps) => {
  const { setValue: setPageValue } = useFieldApi<string>('page');

  const {
    visiblePage,
    pagesCount,
    onPrevPageButtonClick,
    isPrevPageButtonDisabled,
    onNextPageButtonClick,
    isNextPageButtonDisabled,
    setVisiblePage,
    onLeftSpreadButtonClick,
    showLeftSpreadButton,
    onRightSpreadButtonClick,
    showRightSpreadButton,
    generateNumberedPageButtons,
    showLastPageButton,
    onPageSizeChange,
    pageSize,
  } = usePaginationElements({
    onPageChange: (page) => setPageValue(`${page}`),
    defaultPageSize: pageSizeOptions[0].value,
    rowsCount: rowsCount,
  });

  return (
    <div
      className={classNames(
        className,
        'flex justify-between items-center py-3.5 px-3 bg-base-front-inverse'
      )}
    >
      <TySelect
        initialValue={pageSizeOptions.find((o) => o.value === pageSize)}
        onChange={onPageSizeChange}
        options={pageSizeOptions}
        name="pageSize"
      />
      <PaginationRadioGroup
        name="page"
        defaultValue={`${visiblePage}`}
        onChange={(fieldState) => {
          setVisiblePage(Number(fieldState.value));
          onPageChange?.(Number(fieldState.value));
        }}
      >
        <PaginationButton
          disabled={isPrevPageButtonDisabled}
          onClick={onPrevPageButtonClick}
        >
          <ArrowLeftIcon />
        </PaginationButton>
        <PaginationRadio value="1" label="1" />
        {showLeftSpreadButton && (
          <PaginationButton onClick={onLeftSpreadButtonClick}>
            ...
          </PaginationButton>
        )}
        {generateNumberedPageButtons((i) => (
          <PaginationRadio key={i} value={`${i}`} label={`${i}`} />
        ))}
        {showRightSpreadButton && (
          <PaginationButton onClick={onRightSpreadButtonClick}>
            ...
          </PaginationButton>
        )}
        {showLastPageButton && (
          <PaginationRadio value={`${pagesCount}`} label={`${pagesCount}`} />
        )}
        <PaginationButton
          disabled={isNextPageButtonDisabled}
          onClick={onNextPageButtonClick}
        >
          <ArrowRightIcon />
        </PaginationButton>
      </PaginationRadioGroup>
    </div>
  );
};
