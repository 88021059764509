import { ComponentPropsWithoutRef } from 'react';

export const TyNoteIcon = (props: ComponentPropsWithoutRef<'svg'>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    {...props}
  >
    <path d="M13.9336 9.5C13.9336 10.05 13.4836 10.5 12.9336 10.5H3.93359C3.38359 10.5 2.93359 10.05 2.93359 9.5C2.93359 8.95 3.38359 8.5 3.93359 8.5H12.9336C13.4836 8.5 13.9336 8.95 13.9336 9.5ZM2.93359 5.5C2.93359 6.05 3.38359 6.5 3.93359 6.5H12.9336C13.4836 6.5 13.9336 6.05 13.9336 5.5C13.9336 4.95 13.4836 4.5 12.9336 4.5H3.93359C3.38359 4.5 2.93359 4.95 2.93359 5.5ZM9.93359 13.5C9.93359 12.95 9.48359 12.5 8.93359 12.5H3.93359C3.38359 12.5 2.93359 12.95 2.93359 13.5C2.93359 14.05 3.38359 14.5 3.93359 14.5H8.93359C9.48359 14.5 9.93359 14.05 9.93359 13.5ZM17.9436 11.37L18.6536 10.66C19.0436 10.27 19.6736 10.27 20.0636 10.66L20.7736 11.37C21.1636 11.76 21.1636 12.39 20.7736 12.78L20.0636 13.49L17.9436 11.37ZM17.2336 12.08L12.0736 17.24C11.9836 17.33 11.9336 17.45 11.9336 17.59V19C11.9336 19.28 12.1536 19.5 12.4336 19.5H13.8436C13.9736 19.5 14.1036 19.45 14.1936 19.35L19.3536 14.19L17.2336 12.08Z" />
  </svg>
);
