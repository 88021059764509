import { TyReplayIcon } from '@tymbe/ty-components/icons';
import { TyTooltip } from '@tymbe/ty-components/text';
import { IconButton } from '@tymbe/ty-components/button';
import { ApplicationExcuseData } from '@tymbe/schema/application-excuse.interface';
import { useState } from 'react';
import AlertModal from '@tymbe/ty-components/modals/AlertModal';
import { useMutation, useQueryClient } from 'react-query';
import feathersClient from '../../../../apiClient';
import moment from 'moment';
import { useUser } from '../../../../apiClient/ApiContext';
import { toast } from 'react-toastify';
import { AbsenceState } from '@tymbe/schema/enums';
import { processingAbsencesQueryId } from '../ProcessingAbsences.tab';
import { resolvedAbsencesQueryId } from '../ResolvedAbsences.tab';
import { clarificationsQueryId } from '../RequestedClarifications.tab';
import { unexcusedAbsencesQueryId } from '../UnexcusedAbsences.tab';
import { canceledLateQueryId } from '../CanceledLate.tab';

type ExcuseRevertButtonProps = {
  excuse: ApplicationExcuseData;
};

export const ExcuseRevertButton = ({ excuse }: ExcuseRevertButtonProps) => {
  const [showModal, setShowModal] = useState(false);
  const queryClient = useQueryClient();
  const user = useUser();

  const { mutateAsync: revertExcuse, isLoading } = useMutation(
    ['RevertExcuse'],
    async (): Promise<ApplicationExcuseData> => {
      // In this case we want to remove the excuse as it doesn't contain any information from the user
      if (
        excuse.state === AbsenceState.SYSTEM_APPROVED ||
        excuse.state === AbsenceState.SYSTEM_REJECTED
      ) {
        return feathersClient.service('application-excuse').remove(excuse.id);
      }

      return feathersClient.service('application-excuse').patch(excuse.id, {
        state: AbsenceState.PROCESSING,
        reviewed_at: moment.tz('Europe/Prague').toISOString(),
        reviewed_by: user.id,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([processingAbsencesQueryId]);
        queryClient.invalidateQueries([resolvedAbsencesQueryId]);
        queryClient.invalidateQueries([clarificationsQueryId]);
        queryClient.invalidateQueries([unexcusedAbsencesQueryId]);
        queryClient.invalidateQueries([canceledLateQueryId]);
        toast.success(`Omluvenka byla úspěšne odstranena`);
      },
      onError: (error) => {
        toast.error(`Nastala chyba: ${error}`);
      },
    }
  );

  if (
    AbsenceState.HISTORICAL_UNKNOWN === excuse.state ||
    AbsenceState.PROCESSING === excuse.state
  ) {
    return null;
  }

  return (
    <>
      <TyTooltip overlay="Storno omluvenky">
        <IconButton
          className="border border-system-front-error-icon rounded-full p-1 w-6"
          onClick={() => setShowModal(true)}
        >
          <TyReplayIcon className="text-system-front-error-icon" />
        </IconButton>
      </TyTooltip>
      <AlertModal
        showModal={showModal}
        title="Odstranit omluvenku z vyřízených?"
        message="Omluvenka se vrátí zpět do stavu, kde bude čekat na vyřízení"
        confirmButtonText="Odstranit"
        onConfirm={async () => {
          await revertExcuse();
          setShowModal(false);
        }}
        onCancel={() => setShowModal(false)}
        loading={isLoading}
      />
    </>
  );
};
