import { TyNoteIcon } from '@tymbe/ty-components/icons';
import { TyTooltip } from '@tymbe/ty-components/text';
import { IconButton } from '@tymbe/ty-components/button';
import { useState } from 'react';
import AlertModal from '@tymbe/ty-components/modals/AlertModal';
import { useMutation, useQueryClient } from 'react-query';
import feathersClient from '../../../../apiClient';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import { TyTextArea } from '@tymbe/ty-components/inputs';
import { useFormState } from 'informed';
import { ApplicationData } from '@tymbe/schema/application.interface';

type ApplicationAdminNoteButtonProps = {
  application?: ApplicationData;
  queryId: string;
};

export const ApplicationAdminNoteButton = ({
  application,
  queryId,
}: ApplicationAdminNoteButtonProps) => {
  const [showModal, setShowModal] = useState(false);
  const formState = useFormState<{ admin_note: string }>();
  const queryClient = useQueryClient();
  const adminNote = application?.admin_note;

  const { mutateAsync: updateExcuse, isLoading } = useMutation(
    ['UpdateExcuseNote'],
    async (): Promise<any> => {
      if (!application) throw Error('Application not found');

      return feathersClient.service('application').patch(application.id, {
        admin_note: formState.values.admin_note ?? null,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryId]);
        toast.success(`Poznámka byla úspěšně upravena`);
      },
      onError: (error) => {
        toast.error(`Nastala chyba: ${error}`);
      },
    }
  );

  return (
    <>
      <TyTooltip
        overlay={adminNote ?? 'Zadat interní poznámku'}
        placement="top"
      >
        <IconButton onClick={() => setShowModal(true)} className="w-6">
          <TyNoteIcon
            className={classNames(
              !!adminNote && ['text-system-front-default-icon'],
              !adminNote && 'text-special-outline-default'
            )}
          />
        </IconButton>
      </TyTooltip>

      <AlertModal
        showModal={showModal}
        title="Interní poznámka"
        confirmButtonText="Uložit"
        onConfirm={async () => {
          await updateExcuse();
          setShowModal(false);
        }}
        onCancel={() => setShowModal(false)}
        loading={isLoading}
      >
        <div className="px-6 pt-4 w-80">
          <TyTextArea
            name="admin_note"
            defaultValue={adminNote ?? ''}
            rows={5}
          />
        </div>
      </AlertModal>
    </>
  );
};
