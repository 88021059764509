import classNames from 'classnames';
import { ReactNode } from 'react';
import { IconButton } from '../button';
import { ChevronDownIcon } from '../icons';

type CardProps = {
  className?: string;
  children?: ReactNode;
  onClick?: () => void;
  disabled?: boolean;
};

export const TyCard = ({
  className = '',
  onClick,
  disabled = false,
  children,
}: CardProps) => {
  return (
    <div
      className={classNames(
        'shadow-xs',
        'rounded-lg',
        'overflow-hidden',
        !disabled && 'hover:bg-base-back-1-transparent hover:text-base-front-1',
        !disabled && 'transition duration-400 ease-in-out cursor-pointer',
        'flex items-center',
        'justify-between',
        className
      )}
      onClick={onClick}
    >
      <div>{children}</div>
      {!disabled && (
        <IconButton className="ml-2 bg-base-back-1-transparent w-5">
          <ChevronDownIcon className="-rotate-90 text-base-front-3" />
        </IconButton>
      )}
    </div>
  );
};
